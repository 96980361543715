/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Component, OnInit, ElementRef } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { version } from '../../package.json'

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private analytics: AnalyticsService,
    public translate: TranslateService,
    private el: ElementRef,
  ) {
    this.setLanguage(translate);
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.el.nativeElement.removeAttribute("ng-version");//Remove the Angular version from ngx-app element
    this.el.nativeElement.setAttribute("v", version);//Add the web version to ngx-app element
  }

  setLanguage(translate: TranslateService) {
    translate.addLangs(['en', 'zh']);
    // if ((localStorage.getItem('lang') == null) || (localStorage.getItem('lang') == '') || (localStorage.getItem('lang') == undefined) || (localStorage.getItem('lang') == 'null')) {
    translate.setDefaultLang('en');
    // const browserLang = translate.getBrowserLang();//en, zh , zh-TW, zh-HK
    // translate.use(browserLang.match(/en|zh/) ? browserLang : 'en');
    translate.use('en');//暂时写死用英语

    // } else {
    // translate.setDefaultLang(localStorage.getItem('lang'));
    // translate.use(localStorage.getItem('lang'));
    // }
  }
}
