/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { 
  // NbMediaBreakpointsService, 
  // NbMenuService,
  NbSidebarService, 
  NbContextMenuDirective
  // NbThemeService 
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
// import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild(NbContextMenuDirective, { static: false }) contextMenu: NbContextMenuDirective;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  // themes = [
  //   {
  //     value: 'default',
  //     name: 'Light',
  //   },
  //   {
  //     value: 'dark',
  //     name: 'Dark',
  //   },
  //   {
  //     value: 'cosmic',
  //     name: 'Cosmic',
  //   },
  //   {
  //     value: 'corporate',
  //     name: 'Corporate',
  //   },
  // ];

  // currentTheme = 'default';

  userMenu = [ 
    { title: 'Profile', link: 'pages/profile/update-pwd' }, 
    { title: 'Log out', link: 'auth/logout' } 
  ];

  languages = [
    {
      "key": "zh",
      "title": "中文"
    },
    {
      "key": "en",
      "title": "English"
    }
  ]
  currentLang = '';

  constructor(private sidebarService: NbSidebarService,
              // private menuService: NbMenuService,
              // private themeService: NbThemeService,
              private layoutService: LayoutService,
              // private breakpointService: NbMediaBreakpointsService,
              // private translate: TranslateService
              ) {
  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
    this.user = {
      name: localStorage.getItem('sgw-name'),
      picture: "assets/images/logo_square.png"
    };

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
    // this.currentLang = this.getNowLang();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggle() {
      this.contextMenu.toggle();
  }

  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  // }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  // navigateHome() {
  //   this.menuService.navigateHome();
  //   return false;
  // }

  // getNowLang() {
  //   return this.translate.currentLang;
  // }

  // changeLang(event) {
  //   localStorage.setItem('lang', event);
  //   location.reload();
  // }
}
