/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
//websocket类
export class WebSocketService {

  wsUrl: string;
  ws: WebSocket;
  ws2: WebSocket;
  ws3: WebSocket;
  urlPart: string;

  constructor(
    dataService: DataService
  ) {
    this.wsUrl = "wss://www.sg-iot.com:8080";//收费版
    this.urlPart = "/api/ws/plugins/telemetry?token=" + dataService.sgwToken;
  }

  /**
  * 返回一个可观测的流，包括服务器返回的消息
  * @method
  * @param {string} url  要订阅的topic
  * @param {any} sendParams 订阅时发送消息的内容（可选）
  * @author Jeff
  * @return {{mixed}}
  */
  createObservableSocket(sendParams: any): Observable<any> {
    if ((this.ws == undefined) || (this.ws.readyState == 2) || (this.ws.readyState == 3)) {
      this.ws = new WebSocket(this.wsUrl + this.urlPart);
    } else {
      this.ws.send(JSON.stringify(sendParams));
    }
    this.ws.onopen = function () {
      this.send(JSON.stringify(sendParams));
    }

    return new Observable(
      observer => {
        this.ws.onmessage = (event) => observer.next(event.data);
        this.ws.onerror = (event) => observer.error(event);
        this.ws.onclose = (event) => observer.complete();
      })
  }

  closeConnect() {
    if (this.ws !== undefined) {
      this.ws.close();
    }
  }

  createObservableSocket2(sendParams: any): Observable<any> {
    if ((this.ws2 == undefined) || (this.ws2.readyState == 2) || (this.ws2.readyState == 3)) {
      this.ws2 = new WebSocket(this.wsUrl + this.urlPart);
    } else {
      this.ws2.send(JSON.stringify(sendParams));
    }
    this.ws2.onopen = function () {
      this.send(JSON.stringify(sendParams));
    }

    return new Observable(
      observer => {
        this.ws2.onmessage = (event) => observer.next(event.data);
        this.ws2.onerror = (event) => observer.error(event);
        this.ws2.onclose = (event) => observer.complete();
      })
  }

  closeConnect2() {
    if (this.ws2 !== undefined) {
      this.ws2.close();
    }
  }

  createObservableSocket3(sendParams: any): Observable<any> {
    if ((this.ws3 == undefined) || (this.ws3.readyState == 2) || (this.ws3.readyState == 3)) {
      this.ws3 = new WebSocket(this.wsUrl + this.urlPart);
    } else {
      this.ws3.send(JSON.stringify(sendParams));
    }
    this.ws3.onopen = function () {
      this.send(JSON.stringify(sendParams));
    }

    return new Observable(
      observer => {
        this.ws3.onmessage = (event) => observer.next(event.data);
        this.ws3.onerror = (event) => observer.error(event);
        this.ws3.onclose = (event) => observer.complete();
      })
  }

  closeConnect3() {
    if (this.ws3 !== undefined) {
      this.ws3.close();
    }
  }

  //向服务器端发送消息
  sendMessage(message: any, tryAgain = true) {
    if (this.ws !== undefined) {
      if (this.ws.readyState == 1) {
        this.ws.send(JSON.stringify(message));
      } else if (this.ws.readyState == 0) {
        if (tryAgain == true) {
          const that = this;
          setTimeout(function () {
            that.sendMessage(message, false);
          }, 1500);
        }
      }
    }
  }

  //向服务器端发送消息
  sendMessage2(message: any, tryAgain = true) {
    if (this.ws2 !== undefined) {
      if (this.ws2.readyState == 1) {
        this.ws2.send(JSON.stringify(message));
      } else if (this.ws2.readyState == 0) {
        if (tryAgain == true) {
          const that = this;
          setTimeout(function () {
            that.sendMessage2(message, false);
          }, 1500);
        }
      }
    }
  }

  //向服务器端发送消息
  sendMessage3(message: any, tryAgain = true) {
    if (this.ws3 !== undefined) {
      if (this.ws3.readyState == 1) {
        this.ws3.send(JSON.stringify(message));
      } else if (this.ws3.readyState == 0) {
        if (tryAgain == true) {
          const that = this;
          setTimeout(function () {
            that.sendMessage2(message, false);
          }, 1500);
        }
      }
    }
  }

}
