/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { NbAuthService, NbAuthSimpleToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs/observable/of';

@Injectable()
export class RoleProvider implements NbRoleProvider {

    constructor(private authService: NbAuthService) {
    }

    getRole(): Observable<string[]> {
        if (localStorage.getItem("role") !== "" && localStorage.getItem("role") !== null) {
            return observableOf(localStorage.getItem("role").split(","));
        } else {
            if (localStorage.getItem("sgw-token") == null) {//如果在浏览器的其他Tab中已经logout，则自动跳转到根页面（会去login 页面）
                location.href = "/";
            }
            return observableOf(['guest']);
        }
    }
}