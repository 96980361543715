/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { Router } from '@angular/router';

@Injectable()
//数据接口类

export class DataService {

  apiUrl: string;
  pageSize: number;
  isRefreshTokenDone: boolean = false;
  sgwToken: string = localStorage.getItem('sgw-token');
  googleKey = "AIzaSyBuW2DOoYU1GVRv4DkfVYKxeiVaCjG1SkU";

  constructor(
    private http: HttpClient,
    // private router: Router,
  ) {
    this.pageSize = 12;
    // this.apiUrl = "https://localhost";    // Localhost
    // this.apiUrl = "https://dev.sg-iot.com";    // Develop
    // this.apiUrl = "https://dev.sg-iot.com:8443";   // UAT
    this.apiUrl = "https://www.sg-iot.com";    // Production
    // localStorage.getItem('sgw-token');
    // localStorage.getItem('sgw-token-exp');
    // localStorage.getItem('sgw-rf-token');
    // localStorage.getItem('sgw-rf-token-exp');
    // localStorage.getItem('sgw-username');
    // localStorage.getItem('sgw-name');
    // localStorage.getItem('sgw-auth-sdk');
    // localStorage.getItem('sgw-delete-cus');
    // localStorage.getItem('sgw-dev-menu');
  }

  setHeader(): any {
    return new HttpHeaders().set("X-Authorization", "Bearer " + this.sgwToken).set("Accept", "application/json").set("Content-type", "application/json");
  }

  //设置调用User Management System 的API时的header
  setUMSHeader(): any {
    return new HttpHeaders().set("X-Authorization", this.sgwToken).set("Accept", "application/json").set("Content-type", "application/json");
  }

  //判断token是否准备到期
  isTokenExpire(token = localStorage.getItem('sgw-token-exp')): boolean {
    return ((Number(token) - 10) * 1000) <= new Date().getTime();//多减10秒以防万一
  }

  requestPost(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    // if (this.isTokenExpire()) {
    // console.log('Need to refresh token !!');
    // }
    if (options == undefined) {
      options = { headers: this.setHeader() };
    }
    return this.http.post(url, body, options);
  }

  requestPut(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    // if (this.isTokenExpire()) {
    // console.log('Need to refresh token !!');
    // }
    if (options == undefined) {
      options = { headers: this.setHeader() };
    }
    return this.http.put(url, body, options);
  }

  requestDelete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    // if (this.isTokenExpire()) {
    // console.log('Need to refresh token !!');
    // }
    if (options == undefined) {
      options = { headers: this.setHeader() };
    }
    return this.http.delete(url, options);
  }

  //重写httpclient 的Get请求方法
  requestGet(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    // if (this.isTokenExpire()) {//判断token是否失效
    // if (this.isTokenExpire(localStorage.getItem('sgw-rf-token-exp'))) {//判断refresh token是否失效
    // this.router.navigate(['auth/logout']);//如果都失效了，就log out
    // return;
    // } else {
    // return this.refreshToken(url, options);
    // let that = this;
    // for (let i = 0; i <= 5; i++) {
    //   setTimeout(function () {
    //     console.log(i);
    //   }, 1000);
    //   if (this.isRefreshTokenDone == true) {
    //     break;
    //   }
    //   console.log(' i : ', i);
    // }
    // let i = 0;
    // let interval = self.setInterval(() => {
    //   console.log(i);
    //   if (i >= 10) {
    //     clearInterval(interval);
    //   }
    //   if (that.isRefreshTokenDone == true) {
    //     clearInterval(interval);
    //   }
    //   i++;
    // }, 500);
    // }
    // }
    if (options == undefined) {
      options = { headers: this.setHeader() };
    }
    return this.http.get(url, options);

  }

  // async waitRefreshToken(): Promise<any> {
  //   let data = await this.refreshToken();
  //   console.log(data);
  //   return data;
  // }

  refreshToken(url, options): any {
    let head = new HttpHeaders().set("X-Authorization", localStorage.getItem('sgw-rf-token'));
    // return this.http.get(this.apiUrl + '/api/v1/auth/refreshToken', { headers: head });
    return this.http.get(this.apiUrl + '/api/v1/auth/refreshToken', { headers: head }).subscribe(data => {
      // if (data['code'] == 200) {
      return this.http.get(url, options);
      // this.isRefreshTokenDone = true;
      // }
      console.log(data);
    });
  }

  getJwtPayload(jwtToken) {
    let tokenArr = jwtToken.split(".");//分解TOKEN
    return JSON.parse(atob(tokenArr[1]));//获得payload，包含用户信息以及exp time等等
  }

  //get gateway list
  getGateways(pageNum, pageSize = this.pageSize, name): any {
    return this.requestGet(this.apiUrl + '/api/v1/devices/gateways?limit=10000&pageNum=' + pageNum + '&pageSize=' + pageSize + '&name=' + name);
  }

  getTags(pageNum, pageSize = this.pageSize): any {
    return this.requestGet(this.apiUrl + '/api/v1/devices/bletags?limit=10000&pageNum=' + pageNum + '&pageSize=' + pageSize);
  }

  //登录
  login(username, pwd, captcha): any {
    let params = {
      "username": username,
      "password": pwd,
      "validateCode": captcha
    }
    return this.http.post(this.apiUrl + "/api/v1/auth/loginExt", params);
  }

  register(payload): any {
    return this.http.post(this.apiUrl + "/api/v1/auth/register", payload);
  }

  // //获得gateway详情
  getGatewayDetail(deviceId: string): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/' + deviceId + '/attributes/');
  }

  //获得gateway name & type
  getGatewayInfo(deviceId: string): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/' + deviceId);
  }

  //获得tag详情
  getTagsOfGateway(deviceId: string, pageNum, pageSize = this.pageSize): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/' + deviceId + '/bletags?pageNum=' + pageNum + '&pageSize=' + pageSize);
  }

  //得到设备的状态
  getGatewayStatus(deviceId, timeOut = 5000): any {
    let params = {
      "method": "getStatus",
      "params": {},
      "timeout": timeOut
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //得到设备的状态
  getGatewayAPMode(deviceId, timeOut = 10000): any {
    let params = {
      "method": "enterApMode",
      "timeout": timeOut
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //改变gateway的WIFI||catm1等等的状态(on||off)
  setStatus(deviceId, payload): any {
    let params = {
      "method": "setStatus",
      "params": payload,
      "timeout": 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  // 获取设备信息
  getPv2List(deviceId, scanningType): any {
    return this.requestGet(this.apiUrl + "/api/v1/rpc/pv-list/" + deviceId + '/' + scanningType);
  }

  //得到Gateway scan出来的可以provisioning的tag list
  getPvList(deviceId, param): any {
    let params = {
      "method": "getPvList",
      "params": param,
      "timeout": 49000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //tags 的provisioning
  provisioning(deviceId, payload, timeout = 30000): any {
    let params = {
      "method": "pvDev",
      "params": payload,
      "timeout": timeout
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //控制lora tag
  setLora(deviceId, payload, mac): any {
    let params = {
      "method": "setLora",
      "params": payload,
      "timeout": 20000
    };
    params.params['Mac'] = mac;
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //控制Ble tag
  setBle(deviceId, payload, mac, timeout = 6000): any {
    let params = {
      method: "setBle",
      params: payload,
      timeout: timeout
    };
    params.params['Mac'] = mac;
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //重置密码，会发送重置密码的Link给用户的Email
  resetPwdByEmail(email: string, captcha: string): any {
    let params = {
      "email": email,
      "validateCode": captcha
    }
    return this.http.post(this.apiUrl + "/api/v1/auth/resetPasswordByEmail", params);
  }

  //重置密码
  resetPwd(pwd: string, resetToken: string): any {
    let params = {
      "password": pwd,
      "resetToken": resetToken
    }
    return this.http.post(this.apiUrl + "/api/v1/auth/resetPassword", params);
  }

  //激活密码
  activatePwd(pwd: string, activateToken: string): any {
    let params = {
      "password": pwd,
      "activateToken": activateToken
    }
    return this.http.post(this.apiUrl + "/api/v1/auth/activate", params);
  }

  //获取owner list
  ownerList(): any {
    return this.requestGet(this.apiUrl + '/api/v1/owners?limit=100&textSearch=');
  }

  /*
    分配设备给customer
    ownerType :  'CUSTOMER' / 'TENANT'
    ownerId: customer ID
    entityId: device ID
    UserType(selectCustomerMe): 当Assign给自己就为0，assign给下级就为1
    EntityType: 'DEVICE'
  */
  assignDevice2Customer(customerId, deviceId, isTenant = false, selectCustomerMe): any {
    let entityType = isTenant == false ? "CUSTOMER" : "TENANT";
    return this.requestPost(this.apiUrl + "/api/v1/assignOwner/" + entityType + "/" + customerId + "/" + deviceId + "/" + selectCustomerMe + '/DEVICE', {});
  }

  getAssets(type): any {
    return this.requestGet(this.apiUrl + '/api/v1/tenant/assets?limit=100');
  }

  createAsset(params): any {
    return this.requestPost(this.apiUrl + "/api/v1/asset", params);
  }

  updateAsset(params): any {
    return this.requestPut(this.apiUrl + "/api/v1/asset", params);
  }

  deleteAsset(id): any {
    return this.requestDelete(this.apiUrl + "/api/v1/asset/" + id);
  }

  getAssetDetail(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/asset/' + id);
  }

  //分配设备给customer
  assignAsset2Customer(customerId, assetId, isTenant = false, selectCustomerMe): any {
    let entityType = isTenant == false ? "CUSTOMER" : "TENANT";
    return this.requestPost(this.apiUrl + "/api/v1/assignOwner/" + entityType + "/" + customerId + "/" + assetId + "/" + selectCustomerMe + '/ASSET', {});
  }

  getCanList(deviceId): any {
    let params = {
      "method": "getCanList",
      "params": { "type": "can" },
      "timeout": 5000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  getObdStatus(deviceId): any {
    let params = {
      "method": "getOBDStatus",
      "params": {},
      "timeout": 3000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //删除gateway, 通知gateway
  deleteGateway(deviceId): any {
    let params = {
      "method": "delete",
      "params": {
        "type": "gateway",
      },
      "timeout": 5000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  //删除设备（Backend会先通知Gateway，gateway回复后，再删除TB,Provisioning Server）
  //没有mac就删除Gateway，有mac就删除Tag
  deleteDevice(deviceId, mac = '', tagId = ''): any {
    if (mac !== '') {
      return this.requestDelete(this.apiUrl + "/api/v1/device/" + deviceId + '?mac=' + mac + '&tagId=' + tagId);
    } else {
      return this.requestDelete(this.apiUrl + "/api/v1/device/" + deviceId);
    }
  }

  //will not go to PV Server to delete. (Only will send command to gateway and delete in TB)
  deleteOtherDevice(gatewayId, mac, tagId): any {
    return this.requestDelete(this.apiUrl + "/api/v1/other_tag/" + gatewayId + '?mac=' + mac + '&tagId=' + tagId);
  }

  deleteDeviceTB(deviceId): any {
    return this.requestDelete(this.apiUrl + "/api/v1/deviceTB/" + deviceId);
  }

  //更新设备信息，名字，location, description 等等
  updateDeviceInfo(payload): any {
    return this.requestPut(this.apiUrl + "/api/v1/device", payload);
  }

  getDownloadList(): any {
    return this.requestPost(this.apiUrl + "/api/v1/sdk/list?page=1&limit=10000", {});
  }

  getDownloadLink(objId): any {
    return this.requestGet(this.apiUrl + "/api/v1/sdk/download/" + objId);
  }

  getCustomers(): any {
    return this.requestGet(this.apiUrl + '/api/v1/customers?limit=10000');
  }

  getCustomerDetail(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/customer/' + id);
  }

  updateCustomer(params): any {
    return this.requestPut(this.apiUrl + '/api/v1/customer', params);
  }

  deleteCustomer(customerId, isDelete = false): any {
    let header = new HttpHeaders().set("X-Authorization", "Bearer " + this.sgwToken).set("act", isDelete + "");
    return this.requestDelete(this.apiUrl + '/api/v1/customer/' + customerId, { headers: header });
  }

  createCustomer(params): any {
    return this.requestPost(this.apiUrl + '/api/v1/customer', params);
  }

  getUserGroup(customerId = ''): any {
    if (customerId !== '') {
      return this.requestGet(this.apiUrl + '/api/v1/userGroup/customer/' + customerId + '/USER');
    } else {
      return this.requestGet(this.apiUrl + '/api/v1/userGroup/USER');

    }
  }

  getUserList(groupId): any {
    return this.requestGet(this.apiUrl + '/api/v1/user/group/' + groupId + '/USER?limit=10000');
  }

  createUser(params): any {
    return this.requestPost(this.apiUrl + '/api/v1/user', params);
  }

  deleteUser(userId): any {
    return this.requestDelete(this.apiUrl + '/api/v1/user/' + userId);
  }

  updateUser(params): any {
    return this.requestPut(this.apiUrl + '/api/v1/user', params);
  }

  getUserDetail(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/user/' + id);
  }

  getDevices(pageNum, pageSize = this.pageSize): any {
    return this.requestGet(this.apiUrl + '/api/v1/devices/all?limit=10000&pageNum=' + pageNum + '&pageSize=' + pageSize);
  }

  createDevice(params): any {
    return this.requestPost(this.apiUrl + '/api/v1/device', params);
  }

  getFromDevices(deviceId): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/from/' + deviceId);
  }

  getToDevices(deviceId): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/to/' + deviceId);
  }

  getDevicecredential(deviceId): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/credential/' + deviceId);
  }

  saveRelation(fromId, toId): any {
    let params = {
      "fromId": fromId,
      "toId": toId
    }
    return this.requestPost(this.apiUrl + '/api/v1/relation', params);
  }

  deleteRelation(fromId, toId): any {
    return this.requestDelete(this.apiUrl + "/api/v1/delRelation/" + fromId + "/" + toId);
  }

  restartGateway(gatewayId): any {
    let params = {
      "method": "restartNow!!",
      "params": {
      },
      "timeout": 1000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  setEnoceanMac(gatewayId, mac): any {
    let params = {
      "method": "setEOMac",
      "params": {
        "EOMac": mac
      },
      "timeout": 5000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  downloadSDK(url): any {
    return this.requestGet(url);
  }

  getThroughoutData(gatewayId, type) {
    let params = {
      "method": "getVnstat",
      "params": {
        "dev": type
      },
      "timeout": 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  putAttributes(deviceId, payload) {
    return this.requestPost(this.apiUrl + "/api/v1/" + deviceId + "/attributes", payload);
  }

  getGatewayNetConf(gatewayId, type) {
    let params = {
      "method": "getConf",
      "params": {
        "type": type
      },
      "timeout": 20000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  setGatewayNetConf(gatewayId, param) {
    let payload = {
      "method": "setConf",
      "params": param,
      "timeout": 30000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, payload);
  }

  getOtaPackages(mac, type = '1'): any {
    return this.requestGet(this.apiUrl + '/api/v1/bleTagDFU/' + mac + '/' + type);
  }

  getPackageURL(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/DFUFileUrl/' + id);
  }

  sendOta2Gateway(gatewayId, param) {
    let payload = {
      "method": "ota",
      "params": param,
      "timeout": 20000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, payload);
  }

  cancelOta2Gateway(gatewayId: string, tagMac: string) {
    let payload = {
      "method": "delOta",
      "params": {
        type: "bletag",
        mac: tagMac
      },
      "timeout": 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, payload);
  }

  getOtaRecords(mac): any {
    return this.requestGet(this.apiUrl + '/api/v1/getBleRecord?page=1&pageSize=10000&bleMac=' + mac);
  }

  getTagType(id, mac): any {
    return this.http.get(this.apiUrl + '/api/v1/getModelType/' + id + '/' + mac, { headers: this.setHeader(), responseType: 'text' });
  }

  updatePwd(oldPwd, newPwd): any {
    let payload = { "currentPassword": oldPwd, "newPassword": newPwd }
    return this.requestPost(this.apiUrl + "/api/v1/user/changePassword", payload);
  }

  setMeshGroup(macArr, deviceId): any {
    let params = {
      "method": "setMeshGroup",
      "params": {
        Mac: macArr
      },
      "timeout": 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  setMeshByGroup(param, deviceId): any {
    let params = {
      "method": "setMeshByGroup",
      "params": param,
      "timeout": 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + deviceId, params);
  }

  getUserRecord(id): any {
    let params = {
      userId: id
    }
    return this.requestPost(this.apiUrl + '/api/v1/user/userLoginInfo', params);
  }

  postServerAttribute(payload, deviceId): any {
    return this.requestPost(this.apiUrl + "/api/v1/server_attribute/" + deviceId, payload);
  }

  validateEmailInTb(email): any {
    return this.requestGet(this.apiUrl + '/api/v1/user/email/' + email);
  }

  setGps(gatewayId, payload): any {
    let params = {
      method: "setGps",
      params: payload,
      timeout: 20000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  unregisterDevice2PvServer(deviceId): any {
    let payload = [deviceId];
    return this.requestPost(this.apiUrl + "/api/v1/unReg_dev", payload);
  }

  getAssetAttr(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/telemetry/ASSET/' + id + "/values/attributes");
  }

  getDeviceTelemetry(id, keys = ""): any {
    if (keys == "") {
      return this.requestGet(this.apiUrl + '/api/v1/telemetry/DEVICE/' + id + "/values/timeseries");
    } else {
      return this.requestGet(this.apiUrl + '/api/v1/telemetry/DEVICE/' + id + "/values/timeseries?keys=" + keys);
    }
  }

  getDeviceHistoricalTelemetry(id, keys, limit, startTs, endTs): any {
    return this.requestGet(this.apiUrl + '/api/v1/telemetry/DEVICE/' + id + "/values/timeseries?keys=" + keys + "&limit=" + limit + "&startTs=" + startTs + "&endTs=" + endTs);
  }

  getGoogleGeolocation(payload): any {
    // let payload = {
    //   "cellTowers": [{
    //     "homeMobileCountryCode": 460,
    //     "homeMobileNetworkCode": 0,
    //     "radioType": "gsm",
    //     "cellTowers": [
    //       {
    //         "cellId": 51252,
    //         "locationAreaCode": 9967,
    //         "mobileCountryCode": 460,
    //         "mobileNetworkCode": 0
    //       }
    //     ]
    //   }],
    //   "wifiAccessPoints": [
    //     {
    //       "macAddress": "DC:A4:CA:5D:58:AA"
    //     },
    //     {
    //       "macAddress": "DC:FE:18:6B:38:3F"
    //     }
    //   ]
    // }
    return this.http.post("https://www.googleapis.com/geolocation/v1/geolocate?key=" + this.googleKey, payload);
  }

  getGatewayGeolocation(gatewayId): any {
    let params = {
      method: "getGeolocation",
      timeout: 10000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  setGatewayEngineeringMode(gatewayId, on = true): any {
    let params = {
      method: "engineeringMode",
      params: {
        modeSwitch: on == true ? "on" : "off"
      },
      timeout: 2000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  getGatewayOtaVersion(mac): any {
    return this.requestGet(this.apiUrl + '/api/v1/gatewayOta/getVersion/' + mac);
  }

  setGatewayOta(gatewayId, param): any {
    let params = {
      method: "GwOtaCmd",
      params: param,
      timeout: 5000
    };
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  getDeviceByMac(mac): any {
    return this.requestGet(this.apiUrl + '/api/v1/device/mac/' + mac);
  }

  sendCommand2Gateway(gatewayId: string, params) {
    return this.requestPost(this.apiUrl + "/api/v1/rpc/twoway/" + gatewayId, params);
  }

  tagProvisioning(
    params: {
      gwMacAddress: string,
      macAddress: string,
      deviceName: string,
      type: string, // 'ble' || 'beacon'
      location?: string,
      description?: string,
    }
  ) {
    return this.requestPost(this.apiUrl + "/api/v1/reg_tag_tok_web", params);
  }

  // Other type device provisioning (will not go to pv server)
  // Sample params:
  //   {
  //     "gatewayId": "4a2a21c0-5af3-11eb-a594-9549c4e1cb26",
  //     "name": "jtest",
  //     "type": "other",
  //     "mac": ""
  //     "additionalInfo": {
  //         "location": "sdk",
  //         "description": "des"
  //     }
  // }
  tagProvisioningWithoutPvServer(payload): any {
    return this.requestPost(this.apiUrl + "/api/v1/reg_other_tag", payload);
  }

  setTelemetry(id, payload): any {
    return this.requestPost(this.apiUrl + '/api/v1/telemetry/DEVICE/' + id + "/timeseries/CLIENT_SCOPE", payload);
  }

  deleteTelemetry(id, keys, startTs, endTs): any {
    return this.requestDelete(this.apiUrl + '/api/v1/telemetry/DEVICE/' + id + "/timeseries/delete?keys=" + keys + "&startTs=" + startTs + "&endTs=" + endTs);
  }

  // asset
  getFromAssets(deviceId): any {
    return this.requestGet(this.apiUrl + '/api/v1/asset/from/' + deviceId);
  }
  assetRelation(fromId, toId): any {
    let params = {
      "fromId": fromId,
      "toId": toId
    }
    return this.requestPost(this.apiUrl + '/api/v1/assetRelation', params);
  }
  // Building Management
  treeList(type): any {
    return this.requestGet(this.apiUrl + '/api/v1/asset/tree/?type=' + type);
  }
  getFpInfo(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/fp/' + id);
  }
  DeleteFloorPlan(id): any {
    return this.requestDelete(this.apiUrl + '/api/v1/fp/' + id);
  }
  updateActualSize(id, w, h,): any {
    return this.requestPut(this.apiUrl + '/api/v1/fp/' + id + '/' + w + '/' + h, {});
  }
  getMark(): any {
    return this.requestGet(this.apiUrl + '/api/v1/fp/mark');
  }
  //CXE
  searchDevice(name, id): any {
    return this.requestGet(this.apiUrl + '/api/v1/trace/search?name=' + name + '&floorId=' + id);
  }
  getPosition(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/trace/device/' + id);
  }
  getDevicesView(pageNum, pageSize = this.pageSize, type): any {
    return this.requestGet(this.apiUrl + '/api/v1/dde/deviceViews?pageNum=' + pageNum + '&pageSize=' + pageSize + '&type=' + type);
  }
  getAssetDevices(pageNum, pageSize = this.pageSize, status, name, floorId): any {
    return this.requestGet(this.apiUrl + '/api/v1/trace/devices?pageNum=' + pageNum + '&pageSize=' + pageSize + '&status=' + status + '&name=' + name + '&floorId=' + floorId);
  }
  getUserAsset(): any {
    return this.requestGet(this.apiUrl + '/api/v1/user/assets');
  }
  postZoneDevices(payload): any {
    return this.requestPost(this.apiUrl + '/api/v1/trace/zone/devices', payload);
  }
  putDeviceInfo(payload): any {
    return this.requestPut(this.apiUrl + '/api/v1/device', payload);
  }
  //CXE setting
  addGWPosition(payload): any {
    return this.requestPost(this.apiUrl + '/api/v1/fp/assign', payload);
  }
  updateGWPosition(payload): any {
    return this.requestPost(this.apiUrl + '/api/v1/fp/gw-coordinate', payload);
  }

  getAssignableGw(): any {
    return this.requestGet(this.apiUrl + '/api/v1/fp/assignable-gw');
  }
  removeRelation(fromId, toId): any {
    return this.requestDelete(this.apiUrl + "/api/v1/relation/remove/" + fromId + "/ASSET/" + toId + "/DEVICE ");
  }

  //CXE history route
  getHistoryRoute(payload): any {
    return this.requestPost(this.apiUrl + "/api/v1/trace/history-route", payload);
  }
  // EME
  getTrace(pageNum, pageSize = this.pageSize): any {
    return this.requestGet(this.apiUrl + '/api/v1/eme/gw-config?pageNum=' + pageNum + '&pageSize=' + pageSize);
  }
  postWhitelist(payload): any {
    return this.requestPost(this.apiUrl + "/api/v1/eme/gw-config", payload);
  }
  getWhitelist(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/eme/white/' + id);
  }
  getReportData(pageNum, pageSize = this.pageSize): any {
    return this.requestGet(this.apiUrl + '/api/v1/eme/data-map/view?pageNum=' + pageNum + '&pageSize=' + pageSize);
  }
  postReportDownload(payload): any {
    return this.requestPost(this.apiUrl + "/api/v1/eme/data-map/download", payload);
  }
  //DDE
  getDDEDviceInfo(id): any {
    return this.requestGet(this.apiUrl + '/api/v1/dde/device/pcba/' + id);
  }
  savaDDEDviceInfo(params): any {
    return this.requestPost(this.apiUrl + '/api/v1/dde/device/pcba', params);
  }
}