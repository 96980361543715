/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Copyright © <b><a href="https://www.sgwireless.com" target="_blank">SG Wireless™</a></b> {{nowYear}}</span><img class="footer-logo" src="../../../../assets/images/logo-deep-2.svg"/>
  `,
})
export class FooterComponent {
  nowYear: any;

  ngOnInit() {
    let date = new Date;
    this.nowYear = date.getFullYear(); 
  }
}
