/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NbAuthService } from '@nebular/auth';
import { DataService } from './utils/data.service';

/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authService: NbAuthService;

    constructor(
        private injector: Injector,
        private dataService: DataService,
        private http: HttpClient
        ) {
    }

    // public getToken(): string {
    //     return localStorage.getItem('auth_app_token');
    // }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // this.authService = this.injector.get(NbAuthService); // get it here within intercept
        // if (this.isTokenExpire()) {
        //     if ((request.url.indexOf(this.dataService.apiUrl) !== -1) && (request.url !== (this.dataService.apiUrl + '/api/v1/auth/refreshToken'))) {
        //         return this.refreshToken(request, next);
        //     } else {
        //         return next.handle(request);
        //     }
        // } else {
            return next.handle(request);
        // }

        console.log(request);
        // this.authService.isAuthenticated().subscribe((result) => {
            // if (result) {
                // console.log(result);
            // }
        // });

    }

    isTokenExpire(token = localStorage.getItem('sgw-token-exp')): boolean {
        return ((Number(token) - 10) * 1000) <= new Date().getTime();//多减10秒以防万一
    }

    // async waitRefreshToken(): Promise<any> {
    //     let data = await this.refreshToken();
    //     console.log(data);
    //     return data;
    // }
    
    refreshToken(request: HttpRequest<any>, next: HttpHandler): any {
        let head = new HttpHeaders().set("X-Authorization", localStorage.getItem('sgw-rf-token'));
        // return this.http.get(this.dataService.apiUrl + '/api/v1/auth/refreshToken', { headers: head });
        return this.http.get(this.dataService.apiUrl + '/api/v1/auth/refreshToken', { headers: head }).subscribe(data => {
            if (data['code'] == 200) {
                return next.handle(request);

            }
            console.log(data);
        });
    }

}