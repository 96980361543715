/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable()
//数据接口类
export class GlobalFunService {

  token: string;

  constructor(dataService: DataService) {
    this.token = dataService.sgwToken;
  }

  isValidIP(ip) {
    let reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip);
  }

  verifyMac(v) {
    var temp = /^[A-Fa-f0-9]{2}:[A-Fa-f0-9]{2}:[A-Fa-f0-9]{2}:[A-Fa-f0-9]{2}:[A-Fa-f0-9]{2}:[A-Fa-f0-9]{2}$/;
    return !temp.test(v) ? false : true;
  }

  fixZero(num, length = 2) {
    if (num.toString().length >= length) {
      return num
    }
    return this.fixZero(`0${num}`, length)
  }

  getColorArr(index) {
    let colorArr = [
      "#CE0000",
      "#FF0080",
      "#E800E8",
      "#921AFF",
      "#4A4AFF",
      "#0080FF",
      "#00E3E3",
      "#02F78E",
      "#00EC00",
      "#A8FF24",
      "#F9F900",
      "#FFD306",
      "#FFA042",
      "#FF8040",
      "#C48888",
      "#B9B973",
      "#81C0C0",
      "#A6A6D2",
      "#C07AB8",
      "#000000",
      "#4D0000",
      "#600030",
      "#460046",
      "#28004D",
      "#000079",
      "#000079",
      "#003E3E",
      "#006030",
      "#006000",
      "#467500",
      "#424200",
      "#5B4B00",
      "#844200",
      "#642100",
      "#613030",
      "#616130",
      "#336666",
      "#484891",
      "#6C3365",
      "#7B7B7B",
    ]
    return colorArr[index];
  }

  //浮点型除以100（小数点向左移两位）
  gprmc2Wgs84(v: string) {
    let pointIndex = v.indexOf('.');
    if (pointIndex == -1) return null;
    let h = v.substr(0, pointIndex - 2);
    if (h.indexOf('-') !== -1) {//负数
      return Number(h) - Number(v.substring(pointIndex - 2)) / 60;
    } else {
      return Number(h) + Number(v.substring(pointIndex - 2)) / 60;
    }
  }

  //arr1 bigger than arr2
  jsonArrayDiff(arr1, arr2): any {
    let clone = arr1.slice(0);
    for (let i = 0; i < arr2.length; i++) {
      let temp = arr2[i];
      for (let j = 0; j < clone.length; j++) {
        // 普通数组 (temp === clone[j])
        if (temp.id === clone[j].id) {
          clone.splice(j, 1);
        }
      }
    }
    return this.listRemoveRepeat(clone);
  }

  listRemoveRepeat(x) {
    let result = [];
    for (let i = 0; i < x.length; i++) {
      let flag = true;
      let temp = x[i];
      for (let j = 0; j < result.length; j++) {
        // 普通数组 (temp === result[j])
        if (temp.id === result[j].id) {
          flag = false;
          break;
        }
      }
      if (flag) {
        result.push(temp);
      }
    }
    return result;
  }

  //20200120080410 to 2020-01-20T08:04:10Z
  formatGpsTime(v) {
    return v.substr(0, 4) + "-" + v.substr(4, 2) + "-" + v.substr(6, 2) + "T" + v.substr(8, 2) + ":" + v.substr(10, 2) + ":" + v.substr(12, 2) + "Z";
  }

  /**
    * 时间戳转时间
    * @method timestamp2Time
    * @param {number} timestamp  时间戳
    * @param {boolean} isMicroSecond 是否毫秒 
    * @author Jeff  2019-04-25
    * @return string
    */
  timestamp2Time(timestamp, isMicroSecond = true, year = true) {
    let fixZero = (num, length) => {
      if (num.toString().length >= length) {
        return num
      }
      return fixZero(`0${num}`, length)
    }
    if (isMicroSecond === false) {
      timestamp = timestamp * 1000;
    }
    var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate();
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    if (year == true) {
      return Y + M + fixZero(D, 2) + ' ' + fixZero(h, 2) + ':' + fixZero(m, 2) + ':' + fixZero(s, 2);
    } else {
      return M + fixZero(D, 2) + ' ' + fixZero(h, 2) + ':' + fixZero(m, 2) + ':' + fixZero(s, 2);
    }
  }

  //时间戳转成日期和时间的数组，给chart.js的label用, 返回数组
  timestamp2DateTimeArr(timestamp, isMicroSecond = true, year = true) {
    let fixZero = (num, length) => {
      if (num.toString().length >= length) {
        return num
      }
      return fixZero(`0${num}`, length)
    }
    if (isMicroSecond === false) {
      timestamp = timestamp * 1000;
    }
    var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + ' ';
    var M = date.toDateString().split(" ")[1] + " ";
    var D = date.getDate();
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    if (year == true) {
      return [Y + M + fixZero(D, 2), fixZero(h, 2) + ':' + fixZero(m, 2) + ':' + fixZero(s, 2)];
    } else {
      return [M + fixZero(D, 2), fixZero(h, 2) + ':' + fixZero(m, 2) + ':' + fixZero(s, 2)];
    }
  }

  /**
  * EXCEL的Sheet名不能超过31个字符，且不能有 * : ? / \ [ ]
  * @method setExcelSheetName
  * @param {string} name  Sheet Name
  * @author Jeff  2021-11-17
  * @return string
  */
  setExcelSheetName(name) {
    name = name.replace(new RegExp(/:|\?|\*|\\|\/|\[|\]/, "g"), " ");
    return name.length > 30 ? name.substr(name.length - 30) : name;
  }

  validateEmail(email) {
    let reg = new RegExp("^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$");
    return reg.test(email) ? true : false;
  }

  getTokenPayload(token = this.token) {
    let tokenArr = token.split(".");//分解TOKEN
    return JSON.parse(atob(tokenArr[1]));//获得payload，包含用户信息以及exp time等等
  }

  sortArr(arr, byKey = "name") {
    return arr.sort(this.compare(byKey));
  }

  //用于排序
  compare(property) {
    return function (a, b) {
      var value1 = a[property];
      var value2 = b[property];
      return String(value1).localeCompare(value2);
    }
  }

  randomNum(minNum: number, maxNum: number) {
    switch (arguments.length) {
      case 1:
        return parseInt(String(Math.random() * minNum + 1), 10);
      case 2:
        return parseInt(String(Math.random() * (maxNum - minNum + 1) + minNum), 10);
      default:
        return 0;
    }
  }

  //SGT6108-SW-V01.31
  //SGW6008-SW-V01.27-bp.01
  get6008VersionNumber(swV) {
    return Number(swV.split('-')[2].substring(1));
  }

  bin_to_hex(str) {
    let hex_array = [{ key: 0, val: "0000" }, { key: 1, val: "0001" }, { key: 2, val: "0010" }, { key: 3, val: "0011" }, { key: 4, val: "0100" }, { key: 5, val: "0101" }, { key: 6, val: "0110" }, { key: 7, val: "0111" },
    { key: 8, val: "1000" }, { key: 9, val: "1001" }, { key: 'a', val: "1010" }, { key: 'b', val: "1011" }, { key: 'c', val: "1100" }, { key: 'd', val: "1101" }, { key: 'e', val: "1110" }, { key: 'f', val: "1111" }]
    let value = ''
    let list = []
    // console.log(str)
    if (str.length % 4 !== 0) {
      let a = "0000"
      let b = a.substring(0, 4 - str.length % 4)
      str = b.concat(str)
    }
    // console.log(str)
    while (str.length > 4) {
      list.push(str.substring(0, 4))
      str = str.substring(4);
    }
    list.push(str)
    // console.log(list)
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < hex_array.length; j++) {
        if (list[i] == hex_array[j].val) {
          value = value.concat(String(hex_array[j]['key']))
          break
        }
      }
    }
    // console.log(value)
    return value
  }

  hex_to_bin(str) {
    let hex_array = [{ key: 0, val: "0000" }, { key: 1, val: "0001" }, { key: 2, val: "0010" }, { key: 3, val: "0011" }, { key: 4, val: "0100" }, { key: 5, val: "0101" }, { key: 6, val: "0110" }, { key: 7, val: "0111" },
    { key: 8, val: "1000" }, { key: 9, val: "1001" }, { key: 'a', val: "1010" }, { key: 'b', val: "1011" }, { key: 'c', val: "1100" }, { key: 'd', val: "1101" }, { key: 'e', val: "1110" }, { key: 'f', val: "1111" }]
    let value = "";
    for (let i = 0; i < str.length; i++) {
      for (let j = 0; j < hex_array.length; j++) {
        if (str.charAt(i).toLowerCase() == hex_array[j].key) {
          value = value.concat(hex_array[j].val)
          break;
        }
      }
    }
    return value;
  }

  //二进制前面补0
  patchZero(bin: string, len: number = 8) {
    if (bin.length < len) {
      let loopLen = len - bin.length;
      for (let i = 0; i < loopLen; i++) {
        bin = "0" + bin;
      }
    }
    return bin;
  }

  validateDeleteString(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = true;
      if (control.value == "delete") forbidden = false;
      return forbidden ? { value: control.value } : null;
    };
  }

  jsonLength(obj) {
    var size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  countryList() {
    return [
      "Afghanistan",
      "Åland Islands",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Cayman Islands",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands",
      "Colombia",
      "Comoros",
      "Congo",
      "Congo, The Democratic Republic of the",
      "Cook Islands",
      "Costa Rica",
      "Côte d'Ivoire",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands (Malvinas)",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (Vatican City State)",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran, Islamic Republic of",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea, Democratic People's Republic of",
      "Korea, Republic of",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia, Republic Of",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia, Federated States of",
      "Moldova, Republic of",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestinian Territory, Occupied",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French Part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch Part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "United States Minor Outlying Islands",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Viet Nam",
      "Virgin Islands, British",
      "Virgin Islands, U.S.",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe"
    ];
  }
}