/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { LayoutService } from './layout.service';
import { AnalyticsService } from './analytics.service';
import { DataService } from './data.service';
import { WebSocketService } from './websocket.service';
import { GlobalFunService } from './global-fun.service';

export {
  LayoutService,
  AnalyticsService,
  DataService,
  WebSocketService,
  GlobalFunService,
};
