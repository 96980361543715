/* 
 * Copyright (C) 2019 - 2020 SG Wireless Limited - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
} from './utils';

import { DataService, GlobalFunService } from './utils';


import { AuthGuard } from './utils/auth-guard.service';
import { RoleProvider } from './utils/role.provider';


export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        // baseEndpoint: 'https://localhost',   //Localhost
        // baseEndpoint: 'https://dev.sg-iot.com',   // Develop
        // baseEndpoint: 'https://dev.sg-iot.com:8443',    // UAT
        baseEndpoint: 'https://www.sg-iot.com',    // Production
        login: {
          endpoint: '/api/v1/auth/loginExt',
          method: 'post',
        },
        token: {
          class: NbAuthJWTToken,
          key: 'data.token', // this parameter tells where to look for the token
        },
      }),
    ],
    forms: {
      validation: {
        password: {
          required: true,
          minLength: 4,
          maxLength: 20,
        },
        email: {
          required: true,
          minLength: 2,
          maxLength: 30,
        },
        validateCode: {
          required: true,
          minLength: 5,
          maxLength: 5,
        }
      },
      login: {
        redirectDelay: 500,
        socialLinks: false,
      },
      register: {
        socialLinks: false,
      },
      requestPassword: {
        redirectDelay: 5000,
        strategy: 'email',
        showMessages: {
          success: true,
          error: true,
        },
        socialLinks: false,
      },
      resetPassword: {
        redirectDelay: 5000,
        strategy: 'email',
        showMessages: {
          success: true,
          error: true,
        },
        socialLinks: false,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: 'user',
      },
      common: {
        parent: 'guest',
      },
      DeleteCustomer: {
        parent: 'guest',
        view: 'deleteCustomer'//删除customer权限
      },
      sdk_downloader: {
        parent: 'guest',
        view: 'sdkDownload'//查看SDK文件列表权限
      },
      sdk_admin: {
        parent: 'guest',
        view: 'sdkDownload'//查看SDK文件列表权限
      },
      // ViewDeveloper: {
      //   parent: 'guest',
      //   view: 'viewDeveloper'//查看Developer模块权限
      // },
      // ViewHome: {
      //   parent: 'guest',
      //   view: 'ViewHome'
      // },
      // ViewSite: {
      //   parent: 'guest',
      //   view: 'ViewSite'
      // },
      // ViewSmartCity: {
      //   parent: 'guest',
      //   view: 'ViewSmartCity'
      // },
      // ViewUser: {
      //   parent: 'guest',
      //   view: 'ViewUser'
      // },
      // ViewCustomer: {
      //   parent: 'guest',
      //   view: 'ViewCustomer'
      // },
      // ViewHelp: {
      //   parent: 'guest',
      //   view: 'ViewHelp'
      // },
      // ViewBapi: {
      //   parent: 'guest',
      //   view: 'ViewBapi'
      // },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: RoleProvider
  },
  AnalyticsService,
  LayoutService,
  AuthGuard,
  DataService,
  GlobalFunService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
